

*,
*::before,
*::after {
  box-sizing: border-box;
}

/**
Use a more readable tab size (opinionated).
*/

:root {
  -moz-tab-size: 4;
  tab-size: 4;
}

/**
1. Correct the line height in all browsers.
2. Prevent adjustments of font size after orientation changes in iOS.
*/

html {
  line-height: 1.15; /* 1 */
  -webkit-text-size-adjust: 100%; /* 2 */
}

/*
Sections
========
*/

/**
Remove the margin in all browsers.
*/

body {
  margin: 0;
}

/**
Improve consistency of default fonts in all browsers. (https://github.com/sindresorhus/modern-normalize/issues/3)
*/

body {
  font-family:
		system-ui,
		-apple-system, /* Firefox supports this but not yet `system-ui` */
		'Segoe UI',
		Roboto,
		Helvetica,
		Arial,
		sans-serif,
		'Apple Color Emoji',
		'Segoe UI Emoji';
}

/*
Grouping content
================
*/

/**
1. Add the correct height in Firefox.
2. Correct the inheritance of border color in Firefox. (https://bugzilla.mozilla.org/show_bug.cgi?id=190655)
*/

hr {
  height: 0; /* 1 */
  color: inherit; /* 2 */
}

/*
Text-level semantics
====================
*/

/**
Add the correct text decoration in Chrome, Edge, and Safari.
*/

abbr[title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
}

/**
Add the correct font weight in Edge and Safari.
*/

b,
strong {
  font-weight: bolder;
}

/**
1. Improve consistency of default fonts in all browsers. (https://github.com/sindresorhus/modern-normalize/issues/3)
2. Correct the odd 'em' font sizing in all browsers.
*/

code,
kbd,
samp,
pre {
  font-family:
		ui-monospace,
		SFMono-Regular,
		Consolas,
		'Liberation Mono',
		Menlo,
		monospace; /* 1 */
  font-size: 1em; /* 2 */
}

/**
Add the correct font size in all browsers.
*/

small {
  font-size: 80%;
}

/**
Prevent 'sub' and 'sup' elements from affecting the line height in all browsers.
*/

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

/*
Tabular data
============
*/

/**
1. Remove text indentation from table contents in Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=999088, https://bugs.webkit.org/show_bug.cgi?id=201297)
2. Correct table border color inheritance in all Chrome and Safari. (https://bugs.chromium.org/p/chromium/issues/detail?id=935729, https://bugs.webkit.org/show_bug.cgi?id=195016)
*/

table {
  text-indent: 0; /* 1 */
  border-color: inherit; /* 2 */
}

/*
Forms
=====
*/

/**
1. Change the font styles in all browsers.
2. Remove the margin in Firefox and Safari.
*/

button,
input,
optgroup,
select,
textarea {
  font-family: inherit; /* 1 */
  font-size: 100%; /* 1 */
  line-height: 1.15; /* 1 */
  margin: 0; /* 2 */
}

/**
Remove the inheritance of text transform in Edge and Firefox.
1. Remove the inheritance of text transform in Firefox.
*/

button,
select { /* 1 */
  text-transform: none;
}

/**
Correct the inability to style clickable types in iOS and Safari.
*/

button,
[type='button'] {
  -webkit-appearance: button;
}

/**
Remove the inner border and padding in Firefox.
*/

/**
Restore the focus styles unset by the previous rule.
*/

/**
Remove the additional ':invalid' styles in Firefox.
See: https://github.com/mozilla/gecko-dev/blob/2f9eacd9d3d995c937b4251a5557d95d494c9be1/layout/style/res/forms.css#L728-L737
*/

/**
Remove the padding so developers are not caught out when they zero out 'fieldset' elements in all browsers.
*/

legend {
  padding: 0;
}

/**
Add the correct vertical alignment in Chrome and Firefox.
*/

progress {
  vertical-align: baseline;
}

/**
Correct the cursor style of increment and decrement buttons in Safari.
*/

/**
1. Correct the odd appearance in Chrome and Safari.
2. Correct the outline style in Safari.
*/

/**
Remove the inner padding in Chrome and Safari on macOS.
*/

/**
1. Correct the inability to style clickable types in iOS and Safari.
2. Change font properties to 'inherit' in Safari.
*/

/*
Interactive
===========
*/

/*
Add the correct display in Chrome and Safari.
*/

summary {
  display: list-item;
}

/**
 * Manually forked from SUIT CSS Base: https://github.com/suitcss/base
 * A thin layer on top of normalize.css that provides a starting point more
 * suitable for web applications.
 */

/**
 * Removes the default spacing and border for appropriate elements.
 */

blockquote,
dl,
dd,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
figure,
p,
pre {
  margin: 0;
}

button {
  background-color: transparent;
  background-image: none;
}

/**
 * Work around a Firefox/IE bug where the transparent `button` background
 * results in a loss of the default `button` focus styles.
 */

button:focus {
  outline: 1px dotted;
  outline: 5px auto -webkit-focus-ring-color;
}

fieldset {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

/**
 * Tailwind custom reset styles
 */

/**
 * 1. Use the user's configured `sans` font-family (with Tailwind's default
 *    sans-serif font stack as a fallback) as a sane default.
 * 2. Use Tailwind's default "normal" line-height so the user isn't forced
 *    to override it to ensure consistency even when using the default theme.
 */

html {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; /* 1 */
  line-height: 1.5; /* 2 */
}

/**
 * Inherit font-family and line-height from `html` so users can set them as
 * a class directly on the `html` element.
 */

body {
  font-family: inherit;
  line-height: inherit;
}

/**
 * 1. Prevent padding and border from affecting element width.
 *
 *    We used to set this in the html element and inherit from
 *    the parent element for everything else. This caused issues
 *    in shadow-dom-enhanced elements like <details> where the content
 *    is wrapped by a div with box-sizing set to `content-box`.
 *
 *    https://github.com/mozdevs/cssremedy/issues/4
 *
 *
 * 2. Allow adding a border to an element by just adding a border-width.
 *
 *    By default, the way the browser specifies that an element should have no
 *    border is by setting it's border-style to `none` in the user-agent
 *    stylesheet.
 *
 *    In order to easily add borders to elements by just setting the `border-width`
 *    property, we change the default border-style for all elements to `solid`, and
 *    use border-width to hide them instead. This way our `border` utilities only
 *    need to set the `border-width` property instead of the entire `border`
 *    shorthand, making our border utilities much more straightforward to compose.
 *
 *    https://github.com/tailwindcss/tailwindcss/pull/116
 */

*,
::before,
::after {
  box-sizing: border-box; /* 1 */
  border-width: 0; /* 2 */
  border-style: solid; /* 2 */
  border-color: #e4e4e7; /* 2 */
}

/*
 * Ensure horizontal rules are visible by default
 */

hr {
  border-top-width: 1px;
}

/**
 * Undo the `border-style: none` reset that Normalize applies to images so that
 * our `border-{width}` utilities have the expected effect.
 *
 * The Normalize reset is unnecessary for us since we default the border-width
 * to 0 on all elements.
 *
 * https://github.com/tailwindcss/tailwindcss/issues/362
 */

img {
  border-style: solid;
}

textarea {
  resize: vertical;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  opacity: 1;
  color: #a1a1aa;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  opacity: 1;
  color: #a1a1aa;
}

input::placeholder,
textarea::placeholder {
  opacity: 1;
  color: #a1a1aa;
}

button,
[role="button"] {
  cursor: pointer;
}

table {
  border-collapse: collapse;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

/**
 * Reset links to optimize for opt-in styling instead of
 * opt-out.
 */

a {
  color: inherit;
  text-decoration: inherit;
}

/**
 * Reset form element properties that are easy to forget to
 * style explicitly so you don't inadvertently introduce
 * styles that deviate from your design system. These styles
 * supplement a partial reset that is already applied by
 * normalize.css.
 */

button,
input,
optgroup,
select,
textarea {
  padding: 0;
  line-height: inherit;
  color: inherit;
}

/**
 * Use the configured 'mono' font family for elements that
 * are expected to be rendered with a monospace font, falling
 * back to the system monospace stack if there is no configured
 * 'mono' font family.
 */

pre,
code,
kbd,
samp {
  font-family: ui-monospace, SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
}

/**
 * Make replaced elements `display: block` by default as that's
 * the behavior you want almost all of the time. Inspired by
 * CSS Remedy, with `svg` added as well.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

img,
svg,
video,
canvas,
audio,
iframe,
embed,
object {
  display: block;
  vertical-align: middle;
}

/**
 * Constrain images and videos to the parent width and preserve
 * their instrinsic aspect ratio.
 *
 * https://github.com/mozdevs/cssremedy/issues/14
 */

img,
video {
  max-width: 100%;
  height: auto;
}


      [type='text'],
      [type='email'],
      [type='url'],
      [type='password'],
      [type='number'],
      [type='month'],
      [type='time'],
      [type='week'],
      [multiple],
      textarea,
      select
     {
  -webkit-appearance: none;
          appearance: none;
  background-color: #fff;
  border-color: #71717a;
  border-width: 1px;
  border-radius: 0px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}

[type='text']:focus, [type='email']:focus, [type='url']:focus, [type='password']:focus, [type='number']:focus, [type='month']:focus, [type='time']:focus, [type='week']:focus, [multiple]:focus, textarea:focus, select:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
  border-color: #2563eb;
}

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #71717a;
  opacity: 1;
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #71717a;
  opacity: 1;
}

input::placeholder, textarea::placeholder {
  color: #71717a;
  opacity: 1;
}

.footer-bgm {
  background-image: url("../img/footer-bg.png");
  background-repeat: no-repeat;
}

select {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%2371717a' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e");
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1.5em 1.5em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
}

[multiple] {
  background-image: initial;
  background-position: initial;
  background-repeat: unset;
  background-size: initial;
  padding-right: 0.75rem;
  -webkit-print-color-adjust: unset;
          color-adjust: unset;
}


      [type='checkbox'] {
  -webkit-appearance: none;
          appearance: none;
  padding: 0;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
  display: inline-block;
  vertical-align: middle;
  background-origin: border-box;
  -webkit-user-select: none;
      -ms-user-select: none;
          user-select: none;
  -webkit-flex-shrink: 0;
          flex-shrink: 0;
  height: 1rem;
  width: 1rem;
  color: #2563eb;
  background-color: #fff;
  border-color: #71717a;
  border-width: 1px;
}

[type='checkbox'] {
  border-radius: 0px;
}


      [type='checkbox']:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 2px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: #2563eb;
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}


      [type='checkbox']:checked {
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}

[type='checkbox']:checked {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 16 16' fill='white' xmlns='http://www.w3.org/2000/svg'%3e%3cpath d='M12.207 4.793a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414L6.5 9.086l4.293-4.293a1 1 0 011.414 0z'/%3e%3c/svg%3e");
}


      [type='checkbox']:checked:hover,
      [type='checkbox']:checked:focus {
  border-color: transparent;
  background-color: currentColor;
}

[type='checkbox']:indeterminate {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 16 16'%3e%3cpath stroke='white' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M4 8h8'/%3e%3c/svg%3e");
  border-color: transparent;
  background-color: currentColor;
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
}


      [type='checkbox']:indeterminate:hover,
      [type='checkbox']:indeterminate:focus
     {
  border-color: transparent;
  background-color: currentColor;
}

[type='file'] {
  background: unset;
  border-color: inherit;
  border-width: 0;
  border-radius: 0;
  padding: 0;
  font-size: unset;
  line-height: inherit;
}

[type='file']:focus {
  outline: 1px auto -webkit-focus-ring-color;
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1536px;
  }
}

.container {
  width: 100%;
}

@media (min-width: 640px) {
  .container {
    max-width: 640px;
  }
}

@media (min-width: 768px) {
  .container {
    max-width: 768px;
  }
}

@media (min-width: 1024px) {
  .container {
    max-width: 1024px;
  }
}

@media (min-width: 1280px) {
  .container {
    max-width: 1280px;
  }
}

@media (min-width: 1536px) {
  .container {
    max-width: 1280px;
  }
}

.bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
}

.bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.bg-pink-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(236, 72, 153, var(--tw-bg-opacity));
}

.bg-purple-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(233, 213, 255, var(--tw-bg-opacity));
}

.bg-purple-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(168, 85, 247, var(--tw-bg-opacity));
}

.bg-indigo-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(99, 102, 241, var(--tw-bg-opacity));
}

.bg-lightBlue-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(186, 230, 253, var(--tw-bg-opacity));
}

.bg-lightBlue-300 {
  --tw-bg-opacity: 1;
  background-color: rgba(125, 211, 252, var(--tw-bg-opacity));
}

.bg-lightBlue-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(56, 189, 248, var(--tw-bg-opacity));
}

.bg-lightBlue-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(14, 165, 233, var(--tw-bg-opacity));
}

.bg-lightBlue-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 132, 199, var(--tw-bg-opacity));
}

.bg-lightBlue-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(7, 89, 133, var(--tw-bg-opacity));
}

.bg-lightBlue-900 {
  --tw-bg-opacity: 1;
  background-color: rgba(12, 74, 110, var(--tw-bg-opacity));
}

.bg-teal-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(153, 246, 228, var(--tw-bg-opacity));
}

.bg-teal-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 184, 166, var(--tw-bg-opacity));
}

.bg-emerald-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(167, 243, 208, var(--tw-bg-opacity));
}

.bg-emerald-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(52, 211, 153, var(--tw-bg-opacity));
}

.bg-emerald-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(16, 185, 129, var(--tw-bg-opacity));
}

.bg-yellow-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(234, 179, 8, var(--tw-bg-opacity));
}

.bg-orange-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 215, 170, var(--tw-bg-opacity));
}

.bg-orange-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(249, 115, 22, var(--tw-bg-opacity));
}

.bg-red-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(254, 202, 202, var(--tw-bg-opacity));
}

.bg-red-400 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 113, 113, var(--tw-bg-opacity));
}

.bg-red-500 {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 68, 68, var(--tw-bg-opacity));
}

.bg-red-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(220, 38, 38, var(--tw-bg-opacity));
}

.bg-red-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(185, 28, 28, var(--tw-bg-opacity));
}

.bg-blueGray-50 {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}

.bg-blueGray-100 {
  --tw-bg-opacity: 1;
  background-color: rgba(241, 245, 249, var(--tw-bg-opacity));
}

.bg-blueGray-200 {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 232, 240, var(--tw-bg-opacity));
}

.bg-blueGray-600 {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}

.bg-blueGray-700 {
  --tw-bg-opacity: 1;
  background-color: rgba(51, 65, 85, var(--tw-bg-opacity));
}

.bg-blueGray-800 {
  --tw-bg-opacity: 1;
  background-color: rgba(30, 41, 59, var(--tw-bg-opacity));
}

.active\:bg-indigo-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(79, 70, 229, var(--tw-bg-opacity));
}

.active\:bg-lightBlue-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(2, 132, 199, var(--tw-bg-opacity));
}

.active\:bg-blueGray-50:active {
  --tw-bg-opacity: 1;
  background-color: rgba(248, 250, 252, var(--tw-bg-opacity));
}

.active\:bg-blueGray-600:active {
  --tw-bg-opacity: 1;
  background-color: rgba(71, 85, 105, var(--tw-bg-opacity));
}

.bg-center {
  background-position: center;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-cover {
  background-size: cover;
}

.bg-full {
  background-size: 100%;
}

.border-collapse {
  border-collapse: collapse;
}

.border-lightBlue-700 {
  --tw-border-opacity: 1;
  border-color: rgba(3, 105, 161, var(--tw-border-opacity));
}

.border-blueGray-50 {
  --tw-border-opacity: 1;
  border-color: rgba(248, 250, 252, var(--tw-border-opacity));
}

.border-blueGray-100 {
  --tw-border-opacity: 1;
  border-color: rgba(241, 245, 249, var(--tw-border-opacity));
}

.border-blueGray-200 {
  --tw-border-opacity: 1;
  border-color: rgba(226, 232, 240, var(--tw-border-opacity));
}

.border-blueGray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(203, 213, 225, var(--tw-border-opacity));
}

.border-blueGray-500 {
  --tw-border-opacity: 1;
  border-color: rgba(100, 116, 139, var(--tw-border-opacity));
}

.border-blueGray-600 {
  --tw-border-opacity: 1;
  border-color: rgba(71, 85, 105, var(--tw-border-opacity));
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-full {
  border-radius: 9999px;
}

.rounded-t {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}

.rounded-t-lg {
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}

.border-solid {
  border-style: solid;
}

.border-none {
  border-style: none;
}

.border-0 {
  border-width: 0px;
}

.border-2 {
  border-width: 2px;
}

.border {
  border-width: 1px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-t {
  border-top-width: 1px;
}

.border-b {
  border-bottom-width: 1px;
}

.cursor-pointer {
  cursor: pointer;
}

.block {
  display: block;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: -webkit-flex;
  display: flex;
}

.inline-flex {
  display: -webkit-inline-flex;
  display: inline-flex;
}

.table {
  display: table;
}

.hidden {
  display: none;
}

.flex-row {
  -webkit-flex-direction: row;
          flex-direction: row;
}

.flex-col {
  -webkit-flex-direction: column;
          flex-direction: column;
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap;
}

.items-center {
  -webkit-align-items: center;
          align-items: center;
}

.items-stretch {
  -webkit-align-items: stretch;
          align-items: stretch;
}

.content-center {
  -webkit-align-content: center;
          align-content: center;
}

.justify-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.justify-center {
  -webkit-justify-content: center;
          justify-content: center;
}

.justify-between {
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.flex-1 {
  -webkit-flex: 1 1 0%;
          flex: 1 1 0%;
}

.flex-auto {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto;
}

.flex-initial {
  -webkit-flex: 0 1 auto;
          flex: 0 1 auto;
}

.flex-grow {
  -webkit-flex-grow: 1;
          flex-grow: 1;
}

.float-left {
  float: left;
}

.font-light {
  font-weight: 300;
}

.font-normal {
  font-weight: 400;
}

.font-semibold {
  font-weight: 600;
}

.font-bold {
  font-weight: 700;
}

.h-0 {
  height: 0px;
}

.h-2 {
  height: 0.5rem;
}

.h-5 {
  height: 1.25rem;
}

.h-8 {
  height: 2rem;
}

.h-10 {
  height: 2.5rem;
}

.h-12 {
  height: 3rem;
}

.h-16 {
  height: 4rem;
}

.h-20 {
  height: 5rem;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.h-95-px {
  height: 95px;
}

.h-70-px {
  height: 70px;
}

.h-350-px {
  height: 350px;
}

.h-500-px {
  height: 500px;
}

.h-600-px {
  height: 600px;
}

.text-55 {
  font-size: 55rem;
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.leading-none {
  line-height: 1;
}

.leading-snug {
  line-height: 1.375;
}

.leading-normal {
  line-height: 1.5;
}

.leading-relaxed {
  line-height: 1.625;
}

.list-none {
  list-style-type: none;
}

.m-2 {
  margin: 0.5rem;
}

.m-4 {
  margin: 1rem;
}

.-m-16 {
  margin: -4rem;
}

.-m-24 {
  margin: -6rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.mt-4 {
  margin-top: 1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.mt-10 {
  margin-top: 2.5rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-bottom: 3rem;
}

.mt-16 {
  margin-top: 4rem;
}

.mt-20 {
  margin-top: 5rem;
}

.mt-24 {
  margin-top: 6rem;
}

.mb-24 {
  margin-bottom: 6rem;
}

.mt-32 {
  margin-top: 8rem;
}

.mt-48 {
  margin-top: 12rem;
}

.mr-auto {
  margin-right: auto;
}

.ml-auto {
  margin-left: auto;
}

.-ml-4 {
  margin-left: -1rem;
}

.-mt-20 {
  margin-top: -5rem;
}

.-ml-20 {
  margin-left: -5rem;
}

.-mt-24 {
  margin-top: -6rem;
}

.-mt-32 {
  margin-top: -8rem;
}

.-mt-48 {
  margin-top: -12rem;
}

.-mt-64 {
  margin-top: -16rem;
}

.last\:mr-0:last-child {
  margin-right: 0px;
}

.hover\:-mt-4:hover {
  margin-top: -1rem;
}

.max-h-860-px {
  max-height: 860px;
}

.max-w-full {
  max-width: 100%;
}

.max-w-100-px {
  max-width: 100px;
}

.max-w-120-px {
  max-width: 120px;
}

.max-w-150-px {
  max-width: 150px;
}

.max-w-180-px {
  max-width: 180px;
}

.max-w-200-px {
  max-width: 200px;
}

.max-w-210-px {
  max-width: 210px;
}

.max-w-580-px {
  max-width: 580px;
}

.min-h-screen {
  min-height: 100vh;
}

.min-h-screen-75 {
  min-height: 75vh;
}

.min-w-0 {
  min-width: 0px;
}

.min-w-48 {
  min-width: 12rem;
}

.min-w-140-px {
  min-width: 140px;
}

.opacity-50 {
  opacity: 0.5;
}

.opacity-75 {
  opacity: 0.75;
}

.opacity-80 {
  opacity: .8;
}

.outline-none {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.focus\:outline-none:focus {
  outline: 2px solid transparent;
  outline-offset: 2px;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-x-auto {
  overflow-x: auto;
}

.overflow-y-auto {
  overflow-y: auto;
}

.overflow-x-hidden {
  overflow-x: hidden;
}

.p-2 {
  padding: 0.5rem;
}

.p-3 {
  padding: 0.75rem;
}

.p-4 {
  padding: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.p-8 {
  padding: 2rem;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.py-20 {
  padding-top: 5rem;
  padding-bottom: 5rem;
}

.py-24 {
  padding-top: 6rem;
  padding-bottom: 6rem;
}

.py-40 {
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.pt-0 {
  padding-top: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pt-20 {
  padding-top: 5rem;
}

.pb-20 {
  padding-bottom: 5rem;
}

.pt-24 {
  padding-top: 6rem;
}

.pt-32 {
  padding-top: 8rem;
}

.pb-32 {
  padding-bottom: 8rem;
}

.pb-40 {
  padding-bottom: 10rem;
}

.pt-48 {
  padding-top: 12rem;
}

.pb-48 {
  padding-bottom: 12rem;
}

.pb-64 {
  padding-bottom: 16rem;
}

.placeholder-blueGray-300::-webkit-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-placeholder-opacity));
}

.placeholder-blueGray-300:-ms-input-placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-placeholder-opacity));
}

.placeholder-blueGray-300::placeholder {
  --tw-placeholder-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-placeholder-opacity));
}

.pointer-events-none {
  pointer-events: none;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.top-0 {
  top: 0px;
}

.right-0 {
  right: 0px;
}

.bottom-0 {
  bottom: 0px;
}

.left-0 {
  left: 0px;
}

.top-auto {
  top: auto;
}

.bottom-auto {
  bottom: auto;
}

.left-auto {
  left: auto;
}

.-right-100 {
  right: -100%;
}

.-top-225-px {
  top: -225px;
}

.-top-160-px {
  top: -160px;
}

.-top-150-px {
  top: -150px;
}

.-top-94-px {
  top: -94px;
}

.-left-50-px {
  left: -50px;
}

.-top-29-px {
  top: -29px;
}

.-left-20-px {
  left: -20px;
}

.top-25-px {
  top: 25px;
}

.left-40-px {
  left: 40px;
}

.top-95-px {
  top: 95px;
}

.left-145-px {
  left: 145px;
}

.left-195-px {
  left: 195px;
}

.top-210-px {
  top: 210px;
}

.left-260-px {
  left: 260px;
}

* {
  --tw-shadow: 0 0 #0000;
}

.shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-xl {
  --tw-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-2xl {
  --tw-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.shadow-none {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-md:hover {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:shadow-lg:hover {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

* {
  --tw-ring-inset: var(--tw-empty,/*!*/ /*!*/);
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: rgba(59, 130, 246, 0.5);
  --tw-ring-offset-shadow: 0 0 #0000;
  --tw-ring-shadow: 0 0 #0000;
}

.focus\:ring:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.fill-current {
  fill: currentColor;
}

.text-left {
  text-align: left;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.text-pink-400 {
  --tw-text-opacity: 1;
  color: rgba(244, 114, 182, var(--tw-text-opacity));
}

.text-lightBlue-300 {
  --tw-text-opacity: 1;
  color: rgba(125, 211, 252, var(--tw-text-opacity));
}

.text-lightBlue-400 {
  --tw-text-opacity: 1;
  color: rgba(56, 189, 248, var(--tw-text-opacity));
}

.text-lightBlue-500 {
  --tw-text-opacity: 1;
  color: rgba(14, 165, 233, var(--tw-text-opacity));
}

.text-lightBlue-600 {
  --tw-text-opacity: 1;
  color: rgba(2, 132, 199, var(--tw-text-opacity));
}

.text-teal-500 {
  --tw-text-opacity: 1;
  color: rgba(20, 184, 166, var(--tw-text-opacity));
}

.text-emerald-500 {
  --tw-text-opacity: 1;
  color: rgba(16, 185, 129, var(--tw-text-opacity));
}

.text-orange-500 {
  --tw-text-opacity: 1;
  color: rgba(249, 115, 22, var(--tw-text-opacity));
}

.text-red-500 {
  --tw-text-opacity: 1;
  color: rgba(239, 68, 68, var(--tw-text-opacity));
}

.text-blueGray-100 {
  --tw-text-opacity: 1;
  color: rgba(241, 245, 249, var(--tw-text-opacity));
}

.text-blueGray-200 {
  --tw-text-opacity: 1;
  color: rgba(226, 232, 240, var(--tw-text-opacity));
}

.text-blueGray-300 {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-text-opacity));
}

.text-blueGray-400 {
  --tw-text-opacity: 1;
  color: rgba(148, 163, 184, var(--tw-text-opacity));
}

.text-blueGray-500 {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}

.text-blueGray-600 {
  --tw-text-opacity: 1;
  color: rgba(71, 85, 105, var(--tw-text-opacity));
}

.bg-purple {
  background-color: #280e49;
}

.bg-purple-light {
  background-color: #6c578e;
}

.bg-purple-500 {
  background-color: #5c00ce;
}

.text-blueGray-700 {
  --tw-text-opacity: 1;
  color: rgba(51, 65, 85, var(--tw-text-opacity));
}

.text-blueGray-800 {
  --tw-text-opacity: 1;
  color: rgba(30, 41, 59, var(--tw-text-opacity));
}

.hover\:text-lightBlue-600:hover {
  --tw-text-opacity: 1;
  color: rgba(2, 132, 199, var(--tw-text-opacity));
}

.hover\:text-blueGray-300:hover {
  --tw-text-opacity: 1;
  color: rgba(203, 213, 225, var(--tw-text-opacity));
}

.hover\:text-blueGray-500:hover {
  --tw-text-opacity: 1;
  color: rgba(100, 116, 139, var(--tw-text-opacity));
}

.hover\:text-blueGray-700:hover {
  --tw-text-opacity: 1;
  color: rgba(51, 65, 85, var(--tw-text-opacity));
}

.hover\:text-blueGray-800:hover {
  --tw-text-opacity: 1;
  color: rgba(30, 41, 59, var(--tw-text-opacity));
}

.uppercase {
  text-transform: uppercase;
}

.no-underline {
  text-decoration: none;
}

.antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tracking-wide {
  letter-spacing: 0.025em;
}

.align-middle {
  vertical-align: middle;
}

.whitespace-nowrap {
  white-space: nowrap;
}

.break-words {
  overflow-wrap: break-word;
}

.w-5 {
  width: 1.25rem;
}

.w-8 {
  width: 2rem;
}

.w-10 {
  width: 2.5rem;
}

.w-12 {
  width: 3rem;
}

.w-16 {
  width: 4rem;
}

.w-auto {
  width: auto;
}

.w-1\/2 {
  width: 50%;
}

.w-6\/12 {
  width: 50%;
}

.w-10\/12 {
  width: 83.333333%;
}

.w-full {
  width: 100%;
}

.z-2 {
  z-index: 2;
}

.z-3 {
  z-index: 3;
}

.z-10 {
  z-index: 10;
}

.z-40 {
  z-index: 40;
}

.z-50 {
  z-index: 50;
}

.transform {
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  -webkit-transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
          transform: translateX(var(--tw-translate-x)) translateY(var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.transition-all {
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.ease-linear {
  transition-timing-function: linear;
}

.duration-150 {
  transition-duration: 150ms;
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@-webkit-keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@keyframes ping {
  75%, 100% {
    -webkit-transform: scale(2);
            transform: scale(2);
    opacity: 0;
  }
}

@-webkit-keyframes pulse {
  50% {
    opacity: .5;
  }
}

@keyframes pulse {
  50% {
    opacity: .5;
  }
}

@-webkit-keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@keyframes bounce {
  0%, 100% {
    -webkit-transform: translateY(-25%);
            transform: translateY(-25%);
    -webkit-animation-timing-function: cubic-bezier(0.8,0,1,1);
            animation-timing-function: cubic-bezier(0.8,0,1,1);
  }

  50% {
    -webkit-transform: none;
            transform: none;
    -webkit-animation-timing-function: cubic-bezier(0,0,0.2,1);
            animation-timing-function: cubic-bezier(0,0,0.2,1);
  }
}

@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }

  .sm\:mt-0 {
    margin-top: 0px;
  }

  .sm\:ml-1 {
    margin-left: 0.25rem;
  }

  .sm\:mr-2 {
    margin-right: 0.5rem;
  }

  .sm\:pt-0 {
    padding-top: 0px;
  }

  .sm\:w-6\/12 {
    width: 50%;
  }
}

@media (min-width: 768px) {
  .md\:block {
    display: block;
  }

  .md\:flex {
    display: -webkit-flex;
    display: flex;
  }

  .md\:hidden {
    display: none;
  }

  .md\:flex-row {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .md\:flex-col {
    -webkit-flex-direction: column;
            flex-direction: column;
  }

  .md\:flex-nowrap {
    -webkit-flex-wrap: nowrap;
            flex-wrap: nowrap;
  }

  .md\:items-stretch {
    -webkit-align-items: stretch;
            align-items: stretch;
  }

  .md\:justify-start {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  .md\:justify-end {
    -webkit-justify-content: flex-end;
            justify-content: flex-end;
  }

  .md\:justify-between {
    -webkit-justify-content: space-between;
            justify-content: space-between;
  }

  .md\:mt-0 {
    margin-top: 0px;
  }

  .md\:mt-4 {
    margin-top: 1rem;
  }

  .md\:mb-4 {
    margin-bottom: 1rem;
  }

  .md\:mt-40 {
    margin-top: 10rem;
  }

  .md\:mt-64 {
    margin-top: 16rem;
  }

  .md\:ml-64 {
    margin-left: 16rem;
  }

  .md\:min-h-full {
    min-height: 100%;
  }

  .md\:min-w-full {
    min-width: 100%;
  }

  .md\:opacity-100 {
    opacity: 1;
  }

  .md\:overflow-hidden {
    overflow: hidden;
  }

  .md\:overflow-y-auto {
    overflow-y: auto;
  }

  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .md\:pt-0 {
    padding-top: 0px;
  }

  .md\:pb-2 {
    padding-bottom: 0.5rem;
  }

  .md\:pr-12 {
    padding-right: 3rem;
  }

  .md\:pt-32 {
    padding-top: 8rem;
  }

  .md\:fixed {
    position: fixed;
  }

  .md\:relative {
    position: relative;
  }

  .md\:top-0 {
    top: 0px;
  }

  .md\:bottom-0 {
    bottom: 0px;
  }

  .md\:left-0 {
    left: 0px;
  }

  .md\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .md\:text-left {
    text-align: left;
  }

  .md\:w-64 {
    width: 16rem;
  }

  .md\:w-4\/12 {
    width: 33.333333%;
  }

  .md\:w-5\/12 {
    width: 41.666667%;
  }

  .md\:w-6\/12 {
    width: 50%;
  }

  .md\:w-8\/12 {
    width: 66.666667%;
  }
}

@media (min-width: 1024px) {
  .lg\:bg-opacity-0 {
    --tw-bg-opacity: 0;
  }

  .lg\:block {
    display: block;
  }

  .lg\:inline-block {
    display: inline-block;
  }

  .lg\:flex {
    display: -webkit-flex;
    display: flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:flex-row {
    -webkit-flex-direction: row;
            flex-direction: row;
  }

  .lg\:self-center {
    -webkit-align-self: center;
            align-self: center;
  }

  .lg\:justify-start {
    -webkit-justify-content: flex-start;
            justify-content: flex-start;
  }

  .lg\:order-1 {
    -webkit-order: 1;
            order: 1;
  }

  .lg\:order-2 {
    -webkit-order: 2;
            order: 2;
  }

  .lg\:order-3 {
    -webkit-order: 3;
            order: 3;
  }

  .lg\:mb-0 {
    margin-bottom: 0px;
  }

  .lg\:mr-1 {
    margin-right: 0.25rem;
  }

  .lg\:mr-4 {
    margin-right: 1rem;
  }

  .lg\:mt-16 {
    margin-top: 4rem;
  }

  .lg\:ml-auto {
    margin-left: auto;
  }

  .lg\:-ml-16 {
    margin-left: -4rem;
  }

  .lg\:-mt-64 {
    margin-top: -16rem;
  }

  .lg\:p-10 {
    padding: 2.5rem;
  }

  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .lg\:pt-0 {
    padding-top: 0px;
  }

  .lg\:pt-4 {
    padding-top: 1rem;
  }

  .lg\:pt-12 {
    padding-top: 3rem;
  }

  .lg\:pt-24 {
    padding-top: 6rem;
  }

  .lg\:pb-64 {
    padding-bottom: 16rem;
  }

  .lg\:static {
    position: static;
  }

  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-white {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .lg\:text-blueGray-200 {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .lg\:hover\:text-blueGray-200:hover {
    --tw-text-opacity: 1;
    color: rgba(226, 232, 240, var(--tw-text-opacity));
  }

  .lg\:w-auto {
    width: auto;
  }

  .lg\:w-3\/12 {
    width: 25%;
  }

  .lg\:w-4\/12 {
    width: 33.333333%;
  }

  .lg\:w-6\/12 {
    width: 50%;
  }

  .lg\:w-8\/12 {
    width: 66.666667%;
  }

  .lg\:w-9\/12 {
    width: 75%;
  }
}

@media (min-width: 1280px) {
  .xl\:mb-0 {
    margin-bottom: 0px;
  }

  .xl\:w-3\/12 {
    width: 25%;
  }

  .xl\:w-4\/12 {
    width: 33.333333%;
  }

  .xl\:w-6\/12 {
    width: 50%;
  }

  .xl\:w-8\/12 {
    width: 66.666667%;
  }
}

@media (min-width: 1536px) {
}
